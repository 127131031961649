export const loadVueComponent = async (
    selector,
    chunk,
    el,
    componentName,
    hasTemplate
) => {
    const componentDomInstances = document.querySelectorAll(selector)

    const Vue = await import(/* webpackChunkName: 'vue' */ 'vue')
    const loadedComponent = await chunk()

    for (const componentDomInstance of componentDomInstances) {
        if (componentDomInstance) {
            if (!hasTemplate)
                componentDomInstance.setAttribute('inline-template', '')

            new Vue.default({
                el,
                delimiters: ['[[', ']]'],
                components: {
                    [componentName]: loadedComponent.default,
                },
            })
        }
    }
}
