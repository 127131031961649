import { loadVueComponent } from '@common/src/js/utils/vue-loader'
// import { loadBlock } from '@common/src/js/utils/block-loader'

// eslint-disable-next-line
__webpack_public_path__ = '/wp-content/plugins/dbec-events/dist/'

loadVueComponent(
    'ics-download-button',
    () =>
        import(
            /* webpackChunkName: 'test-list-events' */ './components/ICSDownloadButton.vue'
        ),
    'ics-download-button',
    'ics-download-button',
    true
)

loadVueComponent(
    '#list-events',
    () =>
        import(
            /* webpackChunkName: 'test-list-events' */ './components/ListEvents.vue'
        ),
    '.list-events',
    'ListEvents'
)
